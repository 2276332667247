import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../../UI/Modal/Modal';
import Loader from '../../../UI/Loader/Loader';
import FormControlWrap from '../FormControlWrap/FormControlWrap';
import Select from '../../../UI/Input/Select/Select';
import InputDate from '../../../UI/Input/InputDate';
import InputPrice from '../../../UI/Input/InputPrice';
import InputText from '../../../UI/Input/InputText';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import Checkbox from '../../../UI/Input/Checkbox';
import InputRadio from '../../../UI/Input/InputRadio';
import Button from '../../../UI/Input/Button/Button';
import AppointmentEditOptionsForm from '../AppointmentEditOptionsForm/AppointmentEditOptionsForm';
import RefundForm from '../RefundForm/RefundForm';
import ConflictedSchedulePopUp from '../../PopUps/ConflictedSchedulePopUp/ConflictedSchedulePopUp';
import ConfirmPopUp from '../../../PopUps/ConfirmPopUp/ConfirmPopUp';
import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import classes from './AppointmentEditForm.module.css';

const SEX = {
  '-1': '여성매치',
  0: '남녀 모두 매치',
  1: '남성매치',
};
const LEVEL = {
  0: '모든 레벨',
  1: '초급',
  2: '중급',
  3: '아마추어1 이하',
  4: '아마추어2 이하',
  5: '아마추어2 이상',
  6: '세미프로1 이상',
};

const timeOptions = (type, enteredDate, startDate) => {
  let options = [];
  const optionDate = enteredDate
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('. ', '-')
    .slice(0, -1);

  if (type === 'start_time') {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 6; j++) {
        const optionTime = `${i < 10 ? `0${i}` : i}:${j === 0 ? '00' : j * 10}`;
        options.push({
          optionVal: `${optionDate}T${optionTime}`,
          optionTxt: optionTime,
        });
      }
    }
    return options;
  }

  if (type === 'end_time') {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 6; j++) {
        if (+startDate.optionTxt.split(':')[0] + i < 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i}`
              : +startDate.optionTxt.split(':')[0] + i
          }:${j === 0 ? '00' : j * 10}`;

          options.push({
            optionVal: `${startDate.optionVal.split('T')[0]}T${optionTime}`,
            optionTxt: optionTime,
          });
        }

        if (+startDate.optionTxt.split(':')[0] + i >= 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i - 24 < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i - 24}`
              : +startDate.optionTxt.split(':')[0] + i - 24
          }:${j === 0 ? '00' : j * 10}`;

          options.push({
            optionVal: `${new Date(
              startDate.optionVal.split('-')[0],
              +startDate.optionVal.split('-')[1] - 1,
              +startDate.optionVal.split('-')[2].split('T')[0] + 1
            )
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replaceAll('. ', '-')
              .slice(0, -1)}T${optionTime}`,
            optionTxt: optionTime,
          });
        }
      }
    }

    if (startDate.optionTxt.split(':')[1] === '00') {
      return (options = options.slice(6, -5));
    }
    if (startDate.optionTxt.split(':')[1] === '10') {
      return (options = options.slice(7, -6));
    }
    if (startDate.optionTxt.split(':')[1] === '20') {
      return (options = options.slice(8, -7));
    }
    if (startDate.optionTxt.split(':')[1] === '30') {
      return (options = options.slice(9, -8));
    }
    if (startDate.optionTxt.split(':')[1] === '40') {
      return (options = options.slice(10, -9));
    }
    if (startDate.optionTxt.split(':')[1] === '50') {
      return (options = options.slice(11));
    }
  }
};

const AppointmentEditForm = (props) => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const { isLoading, sendRequest: sendEditRequest } = useHttp();
  const {
    adjDateFormat,
    onChangeSchedulerState,
    schedulerState,
    data,
    onClose,
  } = props;
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredProductType, setEnteredProductType] = useState(
    data.productType
  );
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  let defaultSocialMatchStatus = null;
  if (data.socialMatchStatus === 'CONFIRMED') {
    defaultSocialMatchStatus = 'social-public';
  }
  if (
    data.socialMatchStatus === 'NOT_CONFIRMED_CAN_RENT' ||
    data.socialMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT'
  ) {
    defaultSocialMatchStatus = 'social-standby';
  }
  const [enteredSocialStatus, setEnteredSocialStatus] = useState(
    defaultSocialMatchStatus
  );
  let defaultPlabRentalOpt = null;
  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_RENT') {
    defaultPlabRentalOpt = true;
  }
  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT') {
    defaultPlabRentalOpt = false;
  }
  const [enteredPlabRentalOpt, setEnteredPlabRentalOpt] =
    useState(defaultPlabRentalOpt);
  const [enteredDate, setEnteredDate] = useState(new Date(data.date));
  const [enteredStartTime, setEnteredStartTime] = useState({
    optionVal: data.time.startTime,
    optionTxt: data.time.startTime.split('T')[1],
  });
  const [enteredEndTime, setEnteredEndTime] = useState({
    optionVal: data.time.endTime,
    optionTxt: data.time.endTime.split('T')[1],
  });
  const isRepeat = data.isRepeat;
  const [enteredPrice, setEnteredPrice] = useState(data.price);
  const [enteredName, setEnteredName] = useState(data.consumer);
  const [enteredPhoneNum, setEnteredPhoneNum] = useState(data.phoneNum);
  const [enteredNote, setEnteredNote] = useState(data.note);
  const [editOptionsFormAction, setEditOptionsFormAction] = useState({
    type: null,
    isOpen: false,
  });
  const [isRefundFormOpen, setIsRefundFormOpen] = useState(false);
  const [conflictedSchedulePopUp, setConflictedSchedulePopUp] = useState({
    data: [],
    isOpen: false,
  });
  const [confirmPopUp, setConfirmPopUp] = useState({
    actionType: '',
    isOpen: false,
  });
  const [userAgreementPopUp, setUserAgreementPopUp] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const changeProductTypeHandler = (evt) => {
    setEnteredProductType((prevState) => {
      if (prevState === '소셜매치') {
        if (authCtx.userRole !== '마스터') {
          setEnteredNote(null);
        }
        setEnteredName(null);
      }

      if (evt.target.value === '소셜매치' && prevState !== '소셜매치') {
        if (authCtx.userRole === '마스터') {
          setEnteredSocialStatus('social-public');
          setEnteredPlabRentalOpt(false);
        }

        if (authCtx.userRole !== '마스터') {
          setEnteredSocialStatus('social-standby');
          setEnteredStartTime({
            optionVal: data.time.startTime,
            optionTxt: data.time.startTime.split('T')[1],
          });
          setEnteredEndTime({
            optionVal: data.time.endTime,
            optionTxt: data.time.endTime.split('T')[1],
          });
          setEnteredNote(null);
        }
      }

      return evt.target.value;
    });
  };

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };

  const changeSocialStatusHandler = (evt) => {
    setEnteredSocialStatus(evt.target.id);
  };

  const changePlabRentalOptHandler = (evt) => {
    setEnteredPlabRentalOpt(evt.target.checked);
  };

  const changeDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }
    setEnteredDate(new Date(evt.target.value));
    setEnteredStartTime((prevState) => {
      setEnteredEndTime((endTimeprevState) => {
        if (
          endTimeprevState.optionVal.split('T')[0] ===
          prevState.optionVal.split('T')[0]
        ) {
          return {
            optionVal: `${evt.target.value}T${endTimeprevState.optionTxt}`,
            optionTxt: endTimeprevState.optionTxt,
          };
        }

        if (
          endTimeprevState.optionVal.split('T')[0] !==
          prevState.optionVal.split('T')[0]
        ) {
          const enteredDateObj = new Date(evt.target.value);
          const adjEndDate = adjDateFormat(
            new Date(
              enteredDateObj.getFullYear(),
              enteredDateObj.getMonth(),
              enteredDateObj.getDate() + 1
            )
          );

          return {
            optionVal: `${adjEndDate}T${endTimeprevState.optionTxt}`,
            optionTxt: endTimeprevState.optionTxt,
          };
        }
      });

      return {
        optionVal: `${evt.target.value}T${prevState.optionTxt}`,
        optionTxt: prevState.optionTxt,
      };
    });
  };

  const changeStartTimeHandler = (evt) => {
    setEnteredStartTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });

    const timeGap =
      (new Date(enteredEndTime.optionVal) -
        new Date(enteredStartTime.optionVal)) /
      1000 /
      60 /
      60;
    const endDateObj = new Date(evt.target.value);
    const adjEndTimeObj = new Date(
      endDateObj.getFullYear(),
      endDateObj.getMonth(),
      endDateObj.getDate(),
      endDateObj.getHours() + Math.floor(timeGap),
      (endDateObj.getMinutes() / 60 + (timeGap % 1)) * 60
    );
    const adjEndTime = adjEndTimeObj.toLocaleTimeString('ko-KR', {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    });
    setEnteredEndTime({
      optionVal: `${adjDateFormat(adjEndTimeObj)}T${adjEndTime}`,
      optionTxt: adjEndTime,
    });
  };

  const changeEndTimeHandler = (evt) => {
    setEnteredEndTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });
  };

  const changePriceHandler = (evt) => {
    setEnteredPrice(Number(evt.target.value.replaceAll(',', '')));
  };

  const changeNameHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredName(value);
  };

  const changePhoneNumHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredPhoneNum(value);
  };

  const changeNoteHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredNote(value);
  };

  const editOptionsFormOpenHandler = (actionType) => {
    setEditOptionsFormAction((prevState) => ({
      type: actionType ? actionType : null,
      isOpen: !prevState.isOpen,
    }));
  };

  const refundFormOpenHandler = () => {
    setIsRefundFormOpen((prevState) => !prevState);
  };
  const userAgreementPopUpOpenHandler = () => {
    setUserAgreementPopUp((prevState) => !prevState);
  };
  const userAgreementHandler = () => {
    setUserAgreement((prevState) => {
      if (!prevState) setUserAgreementPopUp(false);
      return !prevState;
    });
  };
  const conflictedPopUpHandler = (conflictedSchedule) => {
    setConflictedSchedulePopUp((prevState) => ({
      data: conflictedSchedule ? conflictedSchedule : null,
      isOpen: !prevState.isOpen,
    }));
  };

  const confirmPopUpHandler = (evt) => {
    setConfirmPopUp((prevState) => ({
      actionType: evt.target.innerText,
      isOpen:
        isRepeat || evt.target.innerText === '대관 취소'
          ? false
          : !prevState.isOpen,
    }));
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    let socialStatus;
    if (enteredSocialStatus === 'social-public') {
      socialStatus = 'CONFIRMED';
    }

    if (enteredSocialStatus === 'social-standby') {
      if (enteredPlabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_RENT';
      }

      if (!enteredPlabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_NOT_RENT';
      }
    }
    const updateScheduler = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        if (schedulerState.viewState === 'month') {
          onChangeSchedulerState({
            type: 'CUR_MONTH',
            date: enteredDate,
          });
        } else if (schedulerState.viewState === 'week') {
          onChangeSchedulerState({
            type: 'CUR_WEEK',
            date: enteredDate,
          });
        }
        return;
      }

      switch (data.code) {
        case 'product_overlapped':
          conflictedPopUpHandler(data.conflictedSchedule);
          break;
        default:
          alert(data.message);
      }
    };

    if (
      evt.target.innerText === '일정 취소' ||
      confirmPopUp.actionType === '일정 취소'
    ) {
      if (!isRepeat) {
        sendEditRequest(
          {
            urlPath: `products/${data.id}/once`,
            method: 'DELETE',
          },
          updateScheduler
        );
      }

      if (isRepeat) {
        editOptionsFormOpenHandler('반복 일정 취소');
      }
    }

    const adjustedDate = adjDateFormat(enteredDate);

    if (
      evt.target.innerText === '일정 수정' ||
      confirmPopUp.actionType === '일정 수정'
    ) {
      const adjPrice =
        (enteredProductType !== '예약불가' &&
          enteredProductType !== '소셜매치') ||
        (enteredProductType === '소셜매치' &&
          enteredSocialStatus === 'social-standby' &&
          enteredPlabRentalOpt)
          ? enteredPrice
          : null;
      const adjName =
        enteredProductType !== '예약가능' && enteredProductType !== '예약불가'
          ? enteredName
          : null;
      const adjPhoneNum =
        enteredProductType !== '소셜매치' &&
        enteredProductType !== '예약가능' &&
        enteredProductType !== '예약불가'
          ? enteredPhoneNum
          : null;
      const adjNote = enteredProductType !== '예약가능' ? enteredNote : null;

      if (!isRepeat) {
        sendEditRequest(
          {
            urlPath: `products/${data.id}/once`,
            method: 'PUT',
            body: {
              productType: enteredProductType,
              socialMatchStatus:
                enteredProductType === '소셜매치' ? socialStatus : null,
              date: enteredProductType === '플랩대관' ? adjustedDate : null,
              time: {
                startTime: enteredStartTime.optionVal,
                endTime: enteredEndTime.optionVal,
              },
              price: adjPrice,
              consumer: adjName,
              phoneNum: adjPhoneNum,
              note: adjNote,
              zoneId: enteredStadiumZone,
            },
          },
          updateScheduler
        );
      }

      if (isRepeat) {
        editOptionsFormOpenHandler('반복 일정 수정');
      }
    }
  };

  if (authCtx.userRole !== '마스터') {
    return (
      <Modal
        title='일정 확인'
        onClose={onClose}
        classList={
          editOptionsFormAction.isOpen ||
          confirmPopUp.isOpen ||
          isRefundFormOpen
            ? ['hidden', 'fullScreen']
            : ['fullScreen']
        }
      >
        <form className={classes.AppointmentForm}>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ''
                    : 'product-type'
                }
                className={classes.InputLabel}
              >
                타입
              </label>
              <Select
                attribute={{
                  id: 'product-type',
                  value: enteredProductType,
                  onChange: changeProductTypeHandler,
                  tabIndex:
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? '-1'
                      : '',
                }}
                options={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ['플랩대관', '소셜매치']
                    : [
                        '구장직접',
                        '장기대관',
                        '아카데미',
                        '예약가능',
                        '예약불가',
                      ]
                }
                classList={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ['disabled']
                    : []
                }
              />
            </FormControlWrap>
          </div>
          {enteredProductType === '소셜매치' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label className={classes.InputLabel}>상태</label>
                <InputRadio
                  attribute={{
                    id: 'social-standby',
                    name: 'social-status',
                    onChange: changeSocialStatusHandler,
                    checked: enteredSocialStatus === 'social-standby',
                    disabled: true,
                  }}
                >
                  대기
                </InputRadio>
                <InputRadio
                  attribute={{
                    id: 'social-public',
                    name: 'social-status',
                    onChange: changeSocialStatusHandler,
                    checked: enteredSocialStatus === 'social-public',
                    disabled: true,
                  }}
                >
                  공개
                </InputRadio>
              </FormControlWrap>
            </div>
          )}
          {enteredProductType === '소셜매치' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label className={`${classes.InputLabel} ${classes.hidden}`}>
                  플랩대관 가능 여부
                </label>
                <Checkbox
                  attribute={{
                    name: 'plab-rental-option',
                    onChange: changePlabRentalOptHandler,
                    defaultChecked: enteredPlabRentalOpt,
                    tabIndex: null,
                    disabled: true,
                  }}
                >
                  플랩대관 예약도 받을게요
                </Checkbox>
              </FormControlWrap>
            </div>
          )}
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={
                  enteredProductType === '플랩대관' ||
                  (enteredProductType === '소셜매치' &&
                    enteredSocialStatus === 'social-public')
                    ? ''
                    : 'stadium-zone'
                }
                className={classes.InputLabel}
              >
                구역
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'stadium-zone',
                  value: enteredStadiumZone,
                  onChange: changeStadiumZoneHandler,
                  tabIndex:
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? '-1'
                      : null,
                }}
                options={currentStadium.zones}
                classList={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ['disabled']
                    : []
                }
              />
            </FormControlWrap>
          </div>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label className={classes.InputLabel}>일정</label>
              <InputDate
                attribute={{
                  id: 'appointment-date',
                  value: enteredDate,
                  readOnly: true,
                  tabIndex: -1,
                }}
                classList={['disabled']}
              />
            </FormControlWrap>
          </div>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ''
                    : 'appointment-time'
                }
                className={`${classes.InputLabel} ${classes.hidden}`}
              >
                시간
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'appointment-time',
                  value: enteredStartTime.optionVal,
                  onChange: changeStartTimeHandler,
                  tabIndex:
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? '-1'
                      : '',
                }}
                options={timeOptions('start_time', enteredDate)}
                classList={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ['disabled']
                    : []
                }
              />
              <span style={{ margin: '0 -.3125rem' }}>~</span>
              <Select
                selectType='object'
                attribute={{
                  value: enteredEndTime.optionVal,
                  onChange: changeEndTimeHandler,
                  tabIndex:
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? '-1'
                      : '',
                }}
                options={timeOptions('end_time', enteredDate, enteredStartTime)}
                classList={
                  enteredProductType === '플랩대관' ||
                  enteredProductType === '소셜매치'
                    ? ['disabled']
                    : []
                }
              >
                <span className={classes['AppointmentForm__EndTime-Date']}>
                  {new Date(enteredEndTime.optionVal).toLocaleDateString(
                    'ko-KR',
                    {
                      month: 'long',
                      day: 'numeric',
                      weekday: 'long',
                    }
                  )}
                </span>
              </Select>
            </FormControlWrap>
          </div>
          {data.isRepeat && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap classList={['space-between']}>
                <label className={classes.InputLabel}>반복</label>
                {`매주 ${enteredDate.toLocaleDateString('ko-KR', {
                  weekday: 'long',
                })}, 종료일: ${new Date(
                  data.repeatOpt.endDate
                ).toLocaleDateString('ko-KR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`}
              </FormControlWrap>
            </div>
          )}
          {((enteredProductType !== '예약불가' &&
            enteredProductType !== '소셜매치') ||
            (enteredProductType === '소셜매치' &&
              enteredSocialStatus === 'social-standby' &&
              enteredPlabRentalOpt)) && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label
                  htmlFor={
                    enteredProductType === '플랩대관' ||
                    (enteredProductType === '소셜매치' &&
                      enteredSocialStatus === 'social-standby' &&
                      !enteredPlabRentalOpt)
                      ? null
                      : 'product-price'
                  }
                  className={classes.InputLabel}
                >
                  가격
                </label>
                <InputPrice
                  attribute={{
                    id: 'product-price',
                    value: enteredPrice,
                    onChange: changePriceHandler,
                    tabIndex:
                      enteredProductType === '플랩대관' ||
                      (enteredProductType === '소셜매치' &&
                        enteredSocialStatus === 'social-standby' &&
                        !enteredPlabRentalOpt)
                        ? -1
                        : null,
                  }}
                  classList={
                    enteredProductType === '플랩대관' ||
                    (enteredProductType === '소셜매치' &&
                      enteredSocialStatus === 'social-standby' &&
                      !enteredPlabRentalOpt)
                      ? ['disabled']
                      : []
                  }
                />
              </FormControlWrap>
            </div>
          )}
          {enteredProductType !== '예약가능' &&
            enteredProductType !== '예약불가' && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={
                      enteredProductType === '플랩대관' ||
                      enteredProductType === '소셜매치'
                        ? ''
                        : 'consumer-name'
                    }
                    className={classes.InputLabel}
                  >
                    대관자
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'consumer-name',
                      value:
                        enteredProductType === '소셜매치' &&
                        enteredSocialStatus === 'social-public'
                          ? '플랩풋볼'
                          : enteredProductType === '소셜매치' &&
                            enteredSocialStatus === 'social-standby'
                          ? null
                          : enteredName,
                      onChange: changeNameHandler,
                      placeholder: '이름',
                      tabIndex:
                        enteredProductType === '플랩대관' ||
                        enteredProductType === '소셜매치'
                          ? '-1'
                          : '',
                    }}
                    classList={
                      enteredProductType === '플랩대관' ||
                      enteredProductType === '소셜매치'
                        ? ['disabled']
                        : []
                    }
                  />
                </FormControlWrap>
              </div>
            )}
          {enteredProductType !== '소셜매치' &&
            enteredProductType !== '예약가능' &&
            enteredProductType !== '예약불가' && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={
                      enteredProductType === '플랩대관' ||
                      enteredProductType === '소셜매치'
                        ? ''
                        : 'phone-number'
                    }
                    className={classes.InputLabel}
                  >
                    연락처
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'phone-number',
                      value: enteredPhoneNum,
                      onChange: changePhoneNumHandler,
                      placeholder: '연락처',
                      tabIndex:
                        enteredProductType === '플랩대관' ||
                        enteredProductType === '소셜매치'
                          ? '-1'
                          : '',
                    }}
                    classList={
                      enteredProductType === '플랩대관' ||
                      enteredProductType === '소셜매치'
                        ? ['disabled']
                        : []
                    }
                  />
                </FormControlWrap>
              </div>
            )}
          {enteredProductType !== '예약가능' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label
                  htmlFor={
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? ''
                      : 'note'
                  }
                  className={classes.InputLabel}
                >
                  메모
                </label>
                <Textarea
                  attribute={{
                    id: 'note',
                    value: enteredNote,
                    onChange: changeNoteHandler,
                    placeholder: '기타',
                    tabIndex:
                      enteredProductType === '플랩대관' ||
                      enteredProductType === '소셜매치'
                        ? '-1'
                        : '',
                  }}
                  classList={
                    enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치'
                      ? ['disabled']
                      : []
                  }
                />
              </FormControlWrap>
            </div>
          )}
          {enteredProductType === '소셜매치' &&
            data.matchLevel !== undefined && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap classList={['align--flex-start']}>
                  <label htmlFor='note' className={classes.InputLabel}>
                    기타
                  </label>
                  <div>
                    <div>{LEVEL[data.matchLevel]}</div>
                    <div>
                      {data.playerCnt}vs{data.playerCnt} {SEX[data.matchSex]}
                    </div>
                    {data.managerName && !userAgreement && (
                      <Button
                        attribute={{ type: 'button' }}
                        classList={['Button-planetext']}
                        onClick={userAgreementPopUpOpenHandler}
                      >
                        매니저 정보 보기
                      </Button>
                    )}
                    {data.managerName && userAgreement && (
                      <div>
                        {data.managerName} 매니저 {data.managerPhone}
                      </div>
                    )}
                  </div>
                </FormControlWrap>
              </div>
            )}
          <div className={classes['AppointmentForm__Row']}>
            {((enteredProductType !== '플랩대관' &&
              enteredProductType === '소셜매치' &&
              enteredSocialStatus !== 'social-public') ||
              (enteredProductType !== '플랩대관' &&
                enteredProductType !== '소셜매치')) && (
              <FormControlWrap>
                <Button
                  attribute={{ type: 'button' }}
                  classList={['Button--Delete']}
                  onClick={isRepeat ? submitHandler : confirmPopUpHandler}
                >
                  일정 취소
                </Button>
                <Button
                  attribute={{ type: 'button' }}
                  onClick={isRepeat ? submitHandler : confirmPopUpHandler}
                >
                  일정 수정
                </Button>
              </FormControlWrap>
            )}
            {(enteredProductType === '플랩대관' ||
              (enteredProductType === '소셜매치' &&
                enteredSocialStatus === 'social-public')) && (
              <FormControlWrap>
                <Button
                  attribute={{ type: 'button', tabIndex: -1 }}
                  classList={['Button--Disabled']}
                >
                  {(enteredProductType === '플랩대관' ||
                    enteredProductType === '소셜매치') &&
                    '수정하거나 삭제할 수 없습니다'}
                </Button>
              </FormControlWrap>
            )}
          </div>
        </form>
        {editOptionsFormAction.isOpen && (
          <AppointmentEditOptionsForm
            {...props}
            title={editOptionsFormAction.type}
            enteredDate={enteredDate}
            payload={
              editOptionsFormAction.type === '반복 일정 수정' && {
                productType: enteredProductType,
                socialStatus: enteredSocialStatus,
                plabRentalOpt: enteredPlabRentalOpt,
                time: {
                  startTime: enteredStartTime.optionVal,
                  endTime: enteredEndTime.optionVal,
                },
                price: enteredPrice,
                consumer: enteredName,
                phoneNum: enteredPhoneNum,
                note: enteredNote,
                zoneId: enteredStadiumZone,
              }
            }
            onCloseEditForm={onClose}
            onClose={editOptionsFormOpenHandler}
          />
        )}
        {conflictedSchedulePopUp.isOpen && (
          <ConflictedSchedulePopUp
            onClose={conflictedPopUpHandler}
            time={{
              startTime: enteredStartTime.optionTxt,
              endTime: enteredEndTime.optionTxt,
            }}
            conflictedSchedule={conflictedSchedulePopUp.data}
          />
        )}
        {confirmPopUp.isOpen && (
          <ConfirmPopUp
            actionType={confirmPopUp.actionType}
            onClose={confirmPopUpHandler}
            onSubmit={submitHandler}
          />
        )}
        {userAgreementPopUp && (
          <ConfirmPopUp
            paragraph={
              "매니저의 개인정보는 '매치 진행'을 목적으로 제공됩니다. 구장 관리 등 목적 외 사용 또는 보관은 불가하며, \
              플래버등 제3자에게 공유할 경우 법적 처벌을 받을 수 있습니다."
            }
            onClose={userAgreementPopUpOpenHandler}
            onSubmit={userAgreementHandler}
          />
        )}
        {isLoading && <Loader />}
      </Modal>
    );
  }

  if (authCtx.userRole === '마스터') {
    return (
      <Modal
        title='일정 확인'
        onClose={onClose}
        classList={
          editOptionsFormAction.isOpen ||
          confirmPopUp.isOpen ||
          isRefundFormOpen
            ? ['hidden', 'fullScreen']
            : ['fullScreen']
        }
      >
        <form className={classes.AppointmentForm}>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={
                  enteredProductType === '플랩대관' ? null : 'product-type'
                }
                className={classes.InputLabel}
              >
                타입
              </label>
              <Select
                attribute={{
                  id: 'product-type',
                  value: enteredProductType,
                  onChange: changeProductTypeHandler,
                  tabIndex: enteredProductType === '플랩대관' ? '-1' : null,
                }}
                options={
                  enteredProductType === '플랩대관'
                    ? ['플랩대관']
                    : [
                        '구장직접',
                        '장기대관',
                        '아카데미',
                        '소셜매치',
                        '예약가능',
                        '예약불가',
                      ]
                }
                classList={
                  enteredProductType === '플랩대관' ? ['disabled'] : []
                }
              />
            </FormControlWrap>
          </div>
          {enteredProductType === '소셜매치' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label className={classes.InputLabel}>상태</label>
                <InputRadio
                  attribute={{
                    id: 'social-standby',
                    name: 'social-status',
                    onChange: changeSocialStatusHandler,
                    checked: enteredSocialStatus === 'social-standby',
                  }}
                >
                  대기
                </InputRadio>
                <InputRadio
                  attribute={{
                    id: 'social-public',
                    name: 'social-status',
                    onChange: changeSocialStatusHandler,
                    checked: enteredSocialStatus === 'social-public',
                  }}
                >
                  공개
                </InputRadio>
              </FormControlWrap>
            </div>
          )}
          {enteredProductType === '소셜매치' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label className={`${classes.InputLabel} ${classes.hidden}`}>
                  플랩대관 가능 여부
                </label>
                <Checkbox
                  attribute={{
                    name: 'plab-rental-option',
                    onChange: changePlabRentalOptHandler,
                    defaultChecked: enteredPlabRentalOpt,
                    tabIndex:
                      enteredSocialStatus === 'social-public' ? null : '',
                    disabled:
                      enteredSocialStatus === 'social-public' ? true : false,
                  }}
                >
                  플랩대관 예약도 받을게요
                </Checkbox>
              </FormControlWrap>
            </div>
          )}
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='stadium-zone' className={classes.InputLabel}>
                구역
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'stadium-zone',
                  value: enteredStadiumZone,
                  onChange: changeStadiumZoneHandler,
                }}
                options={currentStadium.zones}
              />
            </FormControlWrap>
          </div>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={
                  enteredProductType === '플랩대관' ? 'appointment-date' : null
                }
                className={classes.InputLabel}
              >
                일정
              </label>
              <InputDate
                attribute={{
                  id: 'appointment-date',
                  value: enteredDate,
                  onChange: changeDateHandler,
                  readOnly: enteredProductType === '플랩대관' ? false : true,
                  tabIndex: enteredProductType === '플랩대관' ? null : -1,
                }}
                classList={
                  enteredProductType === '플랩대관' ? [] : ['disabled']
                }
              />
            </FormControlWrap>
          </div>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor='appointment-time'
                className={`${classes.InputLabel} ${classes.hidden}`}
              >
                시간
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'appointment-time',
                  value: enteredStartTime.optionVal,
                  onChange: changeStartTimeHandler,
                }}
                options={timeOptions('start_time', enteredDate)}
              />
              <span style={{ margin: '0 -.3125rem' }}>~</span>
              <Select
                selectType='object'
                attribute={{
                  value: enteredEndTime.optionVal,
                  onChange: changeEndTimeHandler,
                }}
                options={timeOptions('end_time', enteredDate, enteredStartTime)}
              >
                <span className={classes['AppointmentForm__EndTime-Date']}>
                  {new Date(enteredEndTime.optionVal).toLocaleDateString(
                    'ko-KR',
                    {
                      month: 'long',
                      day: 'numeric',
                      weekday: 'long',
                    }
                  )}
                </span>
              </Select>
            </FormControlWrap>
          </div>
          {data.isRepeat && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap classList={['space-between']}>
                <label className={classes.InputLabel}>반복</label>
                {`매주 ${enteredDate.toLocaleDateString('ko-KR', {
                  weekday: 'long',
                })}, 종료일: ${new Date(
                  data.repeatOpt.endDate
                ).toLocaleDateString('ko-KR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`}
              </FormControlWrap>
            </div>
          )}
          {((enteredProductType !== '예약불가' &&
            enteredProductType !== '소셜매치') ||
            (enteredProductType === '소셜매치' &&
              enteredSocialStatus === 'social-standby' &&
              enteredPlabRentalOpt)) && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label
                  htmlFor={
                    enteredProductType === '플랩대관' ||
                    (enteredProductType === '소셜매치' &&
                      enteredSocialStatus === 'social-standby' &&
                      !enteredPlabRentalOpt)
                      ? null
                      : 'product-price'
                  }
                  className={classes.InputLabel}
                >
                  가격
                </label>
                <InputPrice
                  attribute={{
                    id: 'product-price',
                    value: enteredPrice,
                    onChange: changePriceHandler,
                    tabIndex:
                      enteredProductType === '플랩대관' ||
                      (enteredProductType === '소셜매치' &&
                        enteredSocialStatus === 'social-standby' &&
                        !enteredPlabRentalOpt)
                        ? -1
                        : null,
                  }}
                  classList={
                    enteredProductType === '플랩대관' ||
                    (enteredProductType === '소셜매치' &&
                      enteredSocialStatus === 'social-standby' &&
                      !enteredPlabRentalOpt)
                      ? ['disabled']
                      : []
                  }
                />
              </FormControlWrap>
            </div>
          )}
          {enteredProductType !== '예약가능' &&
            enteredProductType !== '예약불가' && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={
                      enteredProductType === '소셜매치' ? null : 'consumer-name'
                    }
                    className={classes.InputLabel}
                  >
                    대관자
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'consumer-name',
                      value:
                        enteredProductType === '소셜매치' &&
                        enteredSocialStatus === 'social-public'
                          ? '플랩풋볼'
                          : enteredProductType === '소셜매치' &&
                            enteredSocialStatus === 'social-standby'
                          ? null
                          : enteredName,
                      onChange: changeNameHandler,
                      placeholder: '이름',
                      tabIndex: enteredProductType === '소셜매치' ? -1 : null,
                    }}
                    classList={
                      enteredProductType === '소셜매치' ? ['disabled'] : []
                    }
                  />
                </FormControlWrap>
              </div>
            )}
          {enteredProductType !== '소셜매치' &&
            enteredProductType !== '예약가능' &&
            enteredProductType !== '예약불가' && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='phone-number' className={classes.InputLabel}>
                    연락처
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'phone-number',
                      value: enteredPhoneNum,
                      onChange: changePhoneNumHandler,
                      placeholder: '연락처',
                    }}
                  />
                </FormControlWrap>
              </div>
            )}
          {enteredProductType !== '예약가능' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap classList={['align--flex-start']}>
                <label
                  htmlFor='note'
                  className={`${classes.InputLabel} ${classes.textarea}`}
                >
                  메모
                </label>
                <Textarea
                  attribute={{
                    id: 'note',
                    value: enteredNote,
                    onChange: changeNoteHandler,
                    placeholder: '기타',
                  }}
                />
              </FormControlWrap>
            </div>
          )}
          {enteredProductType === '소셜매치' &&
            data.matchLevel !== undefined && (
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap classList={['align--flex-start']}>
                  <label htmlFor='note' className={classes.InputLabel}>
                    기타
                  </label>
                  <div>
                    <div>{LEVEL[data.matchLevel]}</div>
                    <div>
                      {data.playerCnt}vs{data.playerCnt} {SEX[data.matchSex]}
                    </div>
                    {data.managerName && (
                      <div>
                        매니저: {data.managerName} {data.managerPhone}
                      </div>
                    )}
                  </div>
                </FormControlWrap>
              </div>
            )}

          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <Button
                attribute={{ type: 'button' }}
                classList={['Button--Delete']}
                onClick={
                  enteredProductType === '플랩대관'
                    ? refundFormOpenHandler
                    : isRepeat
                    ? submitHandler
                    : confirmPopUpHandler
                }
              >
                일정 취소
              </Button>
              <Button
                attribute={{ type: 'button' }}
                onClick={isRepeat ? submitHandler : confirmPopUpHandler}
              >
                일정 수정
              </Button>
            </FormControlWrap>
          </div>
        </form>
        {editOptionsFormAction.isOpen && (
          <AppointmentEditOptionsForm
            {...props}
            title={editOptionsFormAction.type}
            enteredDate={enteredDate}
            payload={
              editOptionsFormAction.type === '반복 일정 수정' && {
                productType: enteredProductType,
                socialStatus: enteredSocialStatus,
                plabRentalOpt: enteredPlabRentalOpt,
                time: {
                  startTime: enteredStartTime.optionVal,
                  endTime: enteredEndTime.optionVal,
                },
                price: enteredPrice,
                consumer: enteredName,
                phoneNum: enteredPhoneNum,
                note: enteredNote,
                zoneId: enteredStadiumZone,
              }
            }
            onCloseEditForm={onClose}
            onClose={editOptionsFormOpenHandler}
          />
        )}
        {isRefundFormOpen && (
          <RefundForm
            {...props}
            onCloseEditForm={onClose}
            onClose={refundFormOpenHandler}
          />
        )}
        {conflictedSchedulePopUp.isOpen && (
          <ConflictedSchedulePopUp
            onClose={conflictedPopUpHandler}
            time={{
              startTime: enteredStartTime.optionTxt,
              endTime: enteredEndTime.optionTxt,
            }}
            conflictedSchedule={conflictedSchedulePopUp.data}
          />
        )}
        {confirmPopUp.isOpen && (
          <ConfirmPopUp
            actionType={confirmPopUp.actionType}
            onClose={confirmPopUpHandler}
            onSubmit={submitHandler}
          />
        )}
        {isLoading && <Loader />}
      </Modal>
    );
  }
};

export default AppointmentEditForm;
