import { useParams } from 'react-router-dom';
import UtilityMenu from './UtilityMenu/UtilityMenu';
import classes from './GlobalNav.module.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const GlobalNav = (props) => {
  const params = useParams();

  return (
    <nav className={classes.GlobalNav}>
      <header className={classes['GlobalNav__Header']}>
        <div className={classes['GlobalNav__HeaderInner']}></div>
      </header>
      <div className={classes['GlobalNav__Body']}>
        <div className={classes['GlobalNav__BodyInner']}>
          <NavLink
            to={`/schedule/stadium/${params.stadiumId}/zone/${params.zoneId}`}
            className={classes['GlobalNav__Link']}
            activeClassName={classes['GlobalNav__Link--active']}
          >
            구장 일정
          </NavLink>
          <NavLink
            to={`/social-requests/stadium/${params.stadiumId}/zone/${params.zoneId}`}
            className={classes['GlobalNav__Link']}
            activeClassName={classes['GlobalNav__Link--active']}
          >
            소셜매치 추가/변경/취소
          </NavLink>
        </div>
      </div>
      <div className={classes['GlobalNav__Footer']}>
        <a
          href='https://plabstadiumguide.oopy.io/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
          style={{ fontWeight: 700 }}
        >
          구장 제휴 이용 가이드
        </a>
        <a
          href='https://plabfootball.notion.site/24-10-12f8d2532450805f96fcfe2625d872dd'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          소셜매치 추가/변경/취소 가이드
        </a>
        <a
          href='https://plabstadiumguide.oopy.io/0c79d8c7-079a-400e-a577-814134aeef26/'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          구장관리 사이트 이용 가이드
        </a>
        <a
          href='https://tally.so/r/nrL4JM'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          오프라인 배너 신청
        </a>
        <div className={classes['GlobalNav__FooterInner']}>
          <UtilityMenu />
        </div>
      </div>
    </nav>
  );
};

export default GlobalNav;
